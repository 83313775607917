import { Suspense, lazy } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

/// load pages
const PageListLoad = lazy(() => import('src/pages/dashboard/load/list-page'));
const PageAddLoad = lazy(() => import('src/pages/dashboard/load/add-page'));
const PageUpdateLoad = lazy(() => import('src/pages/dashboard/load/update-page'));
const PageListGoat = lazy(() => import('src/pages/dashboard/goat/list-page'));
const PageAddGoatWeight = lazy(() => import('src/pages/dashboard/goat/add-weight-page'));
const PageMarkGoatsButchered = lazy(() => import('src/pages/dashboard/goat/mark-butchered-page'));

/// hub pages
const PageAddHub = lazy(() => import('src/pages/dashboard/hub/add-page'));
const PageListHub = lazy(() => import('src/pages/dashboard/hub/list-page'));

/// load action pages
const Split = lazy(() => import('src/pages/dashboard/split-merge/split/split'));
const SplitListLoad = lazy(() => import('src/pages/dashboard/split-merge/split/list-load'));
const Merge = lazy(() => import('src/pages/dashboard/split-merge/merge/merge'));

/// transit pages
const PageListTransit = lazy(() => import('src/pages/dashboard/transit/list-page'));
const PageAddTransit = lazy(() => import('src/pages/dashboard/transit/add-page'));
const PageUpdateTransit = lazy(() => import('src/pages/dashboard/transit/update-page'));
const PageAddCheckIn = lazy(() => import('src/pages/dashboard/transit/checkin-add-page'));
const PageListExpenseForTransit = lazy(() => import('src/pages/dashboard/transit/expense/list-page'));
const PageUpdateExpenseForTransit = lazy(() => import('src/pages/dashboard/transit/expense/update-page'));

/// sale pages
const PageListSale = lazy(() => import('src/pages/dashboard/sale/list-page'));
const PageAddSale = lazy(() => import('src/pages/dashboard/sale/add-page'));
const PageUpdateSale = lazy(() => import('src/pages/dashboard/sale/update-page'));

/// Expense pages
const PageListExpense = lazy(() => import('src/pages/dashboard/expense/list-page'));
const PageAddExpense = lazy(() => import('src/pages/dashboard/expense/add-page'));
const PageUpdateExpense = lazy(() => import('src/pages/dashboard/expense/update-page'));

/// Transaction pages
const PageListTransaction = lazy(() => import('src/pages/dashboard/transaction/list-page'));
const PageAddTransaction = lazy(() => import('src/pages/dashboard/transaction/add-page'));
const PageUpdateTransaction = lazy(() => import('src/pages/dashboard/transaction/update-page'));

/// Outgoing payment pages
const PageListOutgoingPayment = lazy(() => import('src/pages/dashboard/outgoing-payment/list-page'));
const PageAddOutgoingPayment = lazy(() => import('src/pages/dashboard/outgoing-payment/add-page'));
const PageUpdateOutgoingPayment = lazy(() => import('src/pages/dashboard/outgoing-payment/update-page'));


// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      /// load pages
      {
        path: '',
        children: [
          { element: <PageListLoad />, index: true },
          { path: 'add-load', element: <PageAddLoad /> },
          { path: 'update-load', element: <PageUpdateLoad /> },
          { path: 'list-goat', element: <PageListGoat /> },
          { path: 'add-goat-weight', element: <PageAddGoatWeight /> },
          { path: 'mark-goats-butchered', element: <PageMarkGoatsButchered /> },
        ],
      },
      // { element: <PageListLoad />, index: true },
      /// hub pages
      {
        path: 'hub',
        children: [
          { element: <PageListHub />, index: true },
          { path: 'add-hub', element: <PageAddHub /> },
        ],
      },
      /// load action pages
      {
        path: 'split-merge',
        children: [
          { element: <Merge />, path: 'merge' },
          {
            path: 'split',
            children: [
              { element: <SplitListLoad />, index: true },
              { path: 'list-goat', element: <Split /> },
            ],
          },
        ],
      },
      /// transit pages
      {
        path: 'transit',
        children: [
          {
            path: 'list-transit',
            children: [
              { element: <PageListTransit />, index: true },
              { path: 'list-expense', element: <PageListExpenseForTransit /> },
              { path: 'update-expense', element: <PageUpdateExpenseForTransit /> },
            ],
          },
          { path: 'add-transit', element: <PageAddTransit /> },
          { path: 'update-transit', element: <PageUpdateTransit /> },
          { path: 'add-checkin', element: <PageAddCheckIn /> },
        ],
      },
      /// sale pages
      {
        path: 'sale',
        children: [
          { element: <PageListSale />, index: true },
          { path: 'add-sale', element: <PageAddSale /> },
          { path: 'update-sale', element: <PageUpdateSale /> },
        ],
      },
      /// Expense pages
      {
        path: 'expense',
        children: [
          { element: <PageListExpense />, index: true },
          { path: 'add-expense', element: <PageAddExpense /> },
          { path: 'update-expense', element: <PageUpdateExpense /> },
        ],
      },
      /// transaction pages
      {
        path: 'transaction',
        children: [
          { path: 'list-transaction', element: <PageListTransaction /> },
          { path: 'add-transaction', element: <PageAddTransaction /> },
          { path: 'update-transaction', element: <PageUpdateTransaction /> },
        ]
      },
      /// Outgoing payment pages
      {
        path: 'outgoing-payment',
        children: [
          { element: <PageListOutgoingPayment />, index: true },
          { path: 'add-outgoing-payment', element: <PageAddOutgoingPayment /> },
          { path: 'update-outgoing-payment', element: <PageUpdateOutgoingPayment /> },
        ],
      },
    ],
  },
];
