import {createSlice} from "@reduxjs/toolkit";
import {authApi} from "./api/auth-api";

export const authSlice = createSlice({
    name: "auth",
    initialState: {
        loading: true,
        user: null,
        access: null,
        refresh: null,
    },
    reducers: {
        initialize: (state) => {
            state.loading = false;
        },
        logout: (state) => {
            state.loading = false;
            state.user = null;
            state.access = null;
            state.refresh = null;
        },
        refresh: (state, {payload}) => {
            state.access = payload.access;
            state.refresh = payload.refresh;
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(
                authApi.endpoints.login.matchFulfilled,
                (state, {payload}) => {
                    state.user = payload.user;
                    state.access = payload.access;
                    state.refresh = payload.refresh;
                }
            );
    }
});

export const {initialize, logout, refresh} = authSlice.actions;
export default authSlice.reducer;
