import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {REHYDRATE} from "redux-persist/es/constants";
import {Mutex} from "async-mutex";
import {HOST_API} from "../../config-global";
import {logout, refresh as refreshToken} from "../auth-slice";

const mutex = new Mutex();
export const transformResponse = (baseQueryReturnValue, _meta, _arg) => baseQueryReturnValue.reduce((s, c, rowId) => {
    s[c.id] = {rowId, ...c};
    return s;
}, {});
export const transformErrorResponse = (baseQueryReturnValue, _meta, _arg) => {
    const formatter = (data) => JSON.stringify(data);  // TODO: Write the formatter
    return {
        ...baseQueryReturnValue,
        message: baseQueryReturnValue.data ? formatter(baseQueryReturnValue.data) : 'Something went wrong',
    }
}

const baseQuery = fetchBaseQuery({
    baseUrl: HOST_API,
    prepareHeaders(headers, {getState}) {
        const accessToken = getState().auth.access;
        if (accessToken) {
            headers.set('authorization', `Bearer ${accessToken}`);
        }
        return headers;
    },
});

const customBaseQueryWithReauth = async (args, api, extraOptions) => {
    // Auth example: https://redux-toolkit.js.org/rtk-query/usage/customizing-queries#automatic-re-authorization-by-extending-fetchbasequery
    // Check if already locked
    await mutex.waitForUnlock();
    let result = await baseQuery(args, api, extraOptions);
    if (result.error && result.error.status === 401) {
        // When not locked
        if (!mutex.isLocked()) {
            // Lock before refresh
            const release = await mutex.acquire();
            try {
                // Refresh
                const {refresh} = api.getState().auth;
                const refreshResult = await baseQuery({
                    url: `/users/auth/refresh/`,
                    method: 'POST',
                    body: {refresh}
                }, api, extraOptions);
                if (refreshResult.data) {
                    api.dispatch(refreshToken(refreshResult.data));
                    // Retry the request
                    result = await baseQuery(args, api, extraOptions);
                } else {
                    api.dispatch(logout());
                }
            } finally {
                release();
            }
        } else {
            // Already locked wait until released
            await mutex.waitForUnlock();
            result = await baseQuery(args, api, extraOptions);
        }
    }
    // Transform error response
    if (result.error) {
        result.error = transformErrorResponse(result.error);
    }
    return result;
};

export const api = createApi({
    baseQuery: customBaseQueryWithReauth,
    tagTypes: ['loads', 'sales', 'expenses', 'transactions', 'hubs', 'mandis', 'users', 'warehouses', 'transits', 'checkins', 'outgoing-payments'],
    extractRehydrationInfo(action, {reducerPath}) {
        if (action.type === REHYDRATE) {
            return action.payload?.[reducerPath];
        }
    },
    keepUnusedDataFor: 24 * 3600,  // https://github.com/reduxjs/redux-toolkit/discussions/2347
    refetchOnMountOrArgChange: true,
    endpoints: () => ({})
});
