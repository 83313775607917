// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
};

// ----------------------------------------------------------------------

export const paths = {
  // AUTH
  auth: {
    jwt: {
      login: `${ROOTS.AUTH}/jwt/login`,
      register: `${ROOTS.AUTH}/jwt/register`,
    },
  },
  // DASHBOARD
  dashboard: {
    /// load pages
    root: {
      root: `${ROOTS.DASHBOARD}`,
      addLoad: `${ROOTS.DASHBOARD}/add-load`,
      updateLoad: `${ROOTS.DASHBOARD}/update-load`,
      listGoat: `${ROOTS.DASHBOARD}/list-goat`,
      addGoatWeight: `${ROOTS.DASHBOARD}/add-goat-weight`,
      markGoatsButchered: `${ROOTS.DASHBOARD}/mark-goats-butchered`,
    },
    /// hub pages
    hub: {
      root: `${ROOTS.DASHBOARD}/hub`,
      addHub: `${ROOTS.DASHBOARD}/hub/add-hub`,
    },
    /// load action pages
    splitMerge: {
      root: `${ROOTS.DASHBOARD}/split-merge`,
      merge: `${ROOTS.DASHBOARD}/split-merge/merge`,
      split: {
        root: `${ROOTS.DASHBOARD}/split-merge/split`,
        listGoat: `${ROOTS.DASHBOARD}/split-merge/split/list-goat`,
      },
    },
    /// transit pages
    transit: {
      root: `${ROOTS.DASHBOARD}/transit`,
      listTransit: {
        root: `${ROOTS.DASHBOARD}/transit/list-transit`,
        listExpense: `${ROOTS.DASHBOARD}/transit/list-transit/list-expense`,
        updateExpense: `${ROOTS.DASHBOARD}/transit/list-transit/update-expense`,
      },
      addTransit: `${ROOTS.DASHBOARD}/transit/add-transit`,
      updateTransit: `${ROOTS.DASHBOARD}/transit/update-transit`,
      addCheckIn: `${ROOTS.DASHBOARD}/transit/add-checkin`,
    },
    /// sale pages
    sale: {
      root: `${ROOTS.DASHBOARD}/sale`,
      addSale: `${ROOTS.DASHBOARD}/sale/add-sale`,
      updateSale: `${ROOTS.DASHBOARD}/sale/update-sale`,
    },
    /// Expense pages
    expense: {
      root: `${ROOTS.DASHBOARD}/expense`,
      addExpense: `${ROOTS.DASHBOARD}/expense/add-expense`,
      updateExpense: `${ROOTS.DASHBOARD}/expense/update-expense`,
    },
    /// transactions
    transaction: {
      listTransaction: `${ROOTS.DASHBOARD}/transaction/list-transaction`,
      addTransaction: `${ROOTS.DASHBOARD}/transaction/add-transaction`,
      updateTransaction: `${ROOTS.DASHBOARD}/transaction/update-transaction`,
    },
    /// outgoing payments pages
    outgoingPayment: {
      root: `${ROOTS.DASHBOARD}/outgoing-payment`,
      addOutgoingPayment: `${ROOTS.DASHBOARD}/outgoing-payment/add-outgoing-payment`,
      updateOutgoingPayment: `${ROOTS.DASHBOARD}/outgoing-payment/update-outgoing-payment`,
    },
  },
};
