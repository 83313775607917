import PropTypes from 'prop-types';
import {useCallback, useEffect, useMemo} from 'react';
//
import {useDispatch, useSelector} from "react-redux";
import {AuthContext} from './auth-context';
import {initialize as actionInitialize, logout as actionLogout} from "../../../redux/auth-slice";
import {api} from "../../../redux/api/api";


export function AuthProvider({ children }) {
  const state = useSelector((store) => store.auth);
  const dispatch = useDispatch();

  const initialize = useCallback(() => {
    if (state.user) {
      dispatch(actionInitialize());
    } else {
      dispatch(actionLogout());
      dispatch(api.util.resetApiState());
    }
  }, []);

  useEffect(() => {
    initialize();
  }, []);

  // LOGOUT
  const logout = useCallback(async () => {
    dispatch(actionLogout());
  }, []);

  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      method: 'jwt',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      //
      logout,
    }),
    [logout, state.user, status]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.node,
};
