import { useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
// components
import SvgColor from 'src/components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  loads: icon('ic_goat'),
  addLoad: icon('ic_plus'),
  transits: icon('ic_truck'),
  addTransit: icon('ic_truck_plus'),
  addCheckIn: icon('ic_checkin_plus'),
  sales: icon('ic_cart'),
  addSale: icon('ic_cart_plus'),
  expenses: icon('ic_payment'),
  addExpense: icon('ic_payment_plus'),
  user: icon('ic_user'),
  outgoingPayments: icon('ic_cards'),
  addOutgoingPayment: icon('ic_card_plus'),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const data = useMemo(
    () => [
      // OVERVIEW
      // ----------------------------------------------------------------------
      // title, path, icon, info, children, disabled, caption, roles can be provided
      // See: src/components/nav-section/horizontal/nav-item.js

      /// load pages
      {
        subheader: 'Loads',
        items: [
          { title: 'View loads', path: paths.dashboard.root.root, icon: ICONS.loads },
          { title: 'Add load', path: paths.dashboard.root.addLoad, icon: ICONS.addLoad },
        ],
      },
      /// load action pages
      // {
      //   subheader: 'Split/Merge',
      //   items: [
      //     { title: 'Merge loads', path: paths.dashboard.splitMerge.merge, icon: ICONS.loads },
      //     {
      //       title: 'Split loads',
      //       icon: ICONS.loads,
      //       path: paths.dashboard.splitMerge.split.root,
      //       hiddenChildren: [
      //         { title: 'list goat', path: paths.dashboard.splitMerge.split.listGoat },
      //       ],
      //     },
      //   ],
      // },

      /// transit pages
      {
        subheader: 'Transit',
        items: [
          {
            title: 'View Transits',
            icon: ICONS.transits,
            path: paths.dashboard.transit.listTransit.root,
            hiddenChildren: [
              { title: 'Update Transit', path: paths.dashboard.transit.updateTransit },
              { title: 'View expenses', path: paths.dashboard.transit.listTransit.listExpense },
              { title: 'Update expense', path: paths.dashboard.transit.listTransit.updateExpense },
            ],
          },
          { title: 'Add Transit', path: paths.dashboard.transit.addTransit, icon: ICONS.addTransit },
          { title: 'Add CheckIn', path: paths.dashboard.transit.addCheckIn, icon: ICONS.addCheckIn },
        ],
      },
      /// sale pages
      {
        subheader: 'Sales',
        items: [
          { title: 'View sales', path: paths.dashboard.sale.root, icon: ICONS.sales },
          { title: 'Add sale', path: paths.dashboard.sale.addSale, icon: ICONS.addSale },
        ],
      },
      /// Expense pages
      {
        subheader: 'Expenses',
        items: [
          { title: 'View expenses', path: paths.dashboard.expense.root, icon: ICONS.expenses },
          { title: 'Add expense', path: paths.dashboard.expense.addExpense, icon: ICONS.addExpense },
        ],
      },
      /// Outgoing payment pages
      {
        subheader: 'Outgoing Payments',
        items: [
          { title: 'View payments', path: paths.dashboard.outgoingPayment.root, icon: ICONS.outgoingPayments },
          { title: 'Add payment', path: paths.dashboard.outgoingPayment.addOutgoingPayment, icon: ICONS.addOutgoingPayment },
        ],
      },
    ],
    []
  );

  return data;
}
