// scroll bar
import 'simplebar-react/dist/simplebar.min.css';

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';

// ----------------------------------------------------------------------

// routes
import Router from 'src/routes/sections';
// theme
import ThemeProvider from 'src/theme';
// hooks
import { useScrollToTop } from 'src/hooks/use-scroll-to-top';
// components
import ProgressBar from 'src/components/progress-bar';
import MotionLazy from 'src/components/animate/motion-lazy';
import { SettingsProvider, SettingsDrawer } from 'src/components/settings';
// auth
import { AuthProvider, AuthConsumer } from 'src/auth/context/jwt';
import {Provider} from 'react-redux';
import {PersistGate} from "redux-persist/integration/react";
import {persistor, store} from "./redux/store";

// ----------------------------------------------------------------------

export default function App() {
  useScrollToTop();

  return (
      <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
              <AuthProvider>
                  <SettingsProvider
                      defaultSettings={{
                          themeMode: 'light', // 'light' | 'dark'
                          themeDirection: 'ltr', //  'rtl' | 'ltr'
                          themeContrast: 'default', // 'default' | 'bold'
                          themeLayout: 'vertical', // 'vertical' | 'horizontal' | 'mini'
                          themeColorPresets: 'default', // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
                          themeStretch: true,
                      }}
                  >
                      <ThemeProvider>
                          <MotionLazy>
                              <SettingsDrawer/>
                              <ProgressBar/>
                              <AuthConsumer>
                                  <Router/>
                              </AuthConsumer>
                          </MotionLazy>
                      </ThemeProvider>
                  </SettingsProvider>
              </AuthProvider>
          </PersistGate>
      </Provider>
  );
}
