import {api} from "./api";

export const authApi = api.injectEndpoints({
    endpoints: (build) => {
        const authBaseUrl = '/users/auth';
        return {
            login: build.mutation({
                query: (credentials) => ({url: `${authBaseUrl}/login/`, method: 'POST', body: credentials}),
            }),
        }
    },
    overrideExisting: true,
});

export const {useLoginMutation} = authApi;
